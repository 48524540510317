import * as React from 'react'
import { Link } from 'gatsby'

// Components
import Layout from '../components/layout/Layout'

const NotFoundPage = () => {

  return (
    <Layout pageTitle="Contact" headerClassPosition="sticky-top">
      <section className="contact">
        <div className="container">
          <div className="section-title">
             <h2>Page introuvable</h2>
          </div>
          <div className="text-center">
              <h5>Nous n'avons pas trouvé ce que vous cherchez.</h5>
              <br />
              {process.env.NODE_ENV === "development" ? (
                <>
                  <br />
                  Try creating a page in <code>src/pages/</code>
                  <br />
                </>
              ) : null}
              <br />
              <Link to="/">Aller à l'accueil</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

